<template>
  <div
    class="list-detail-grid list-detail"
    :class="{ 'search-no-product-bg': totalItems == 0 && !loading }"
  >
    <v-container>
      <v-row class="list-detail-header align-center mb-6">
        <!-- <v-col cols="12" md="6" class="d-flex flex-column flex-md-row"> -->
        <v-col
          cols="12"
          sm="8"
          offset-sm="4"
          lg="9"
          offset-lg="3"
          xl="10"
          offset-xl="2"
        >
          <div
            class="d-flex justify-beetween justify-sm-start justify-md-end"
            :class="{ 'flex-column': $vuetify.breakpoint.xs }"
          >
            <h3 class="list-name secondary--text">
              <strong>Lista: </strong><span>{{ list.name }}</span>
              <span class="ml-1">({{ totalItems }} prodotti)</span>
            </h3>
            <v-spacer />
            <v-tooltip
              top
              :open-on-hover="true"
              :open-on-click="true"
              max-width="500"
              :disabled="editable"
              class="mx-1"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    outlined
                    color="primary"
                    class="bg-white"
                    depressed
                    :disabled="!editable"
                    @click="emptyList()"
                  >
                    <span>{{ $t("profile.lists.emptyList") }}</span>
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("profile.lists.notEditable") }}</span>
            </v-tooltip>

            <v-btn
              outlined
              color="primary"
              class="bg-white mx-0 ml-sm-2 mr-sm-0"
              depressed
              @click="goToListView"
            >
              <span>
                {{ $t("profile.lists.switchToList") }}
              </span>
            </v-btn>
            <v-btn
              color="primary"
              class="mx-0 ml-sm-2 mr-sm-0"
              depressed
              @click="addAllToCart"
            >
              <span>
                {{ $t("profile.lists.addAllToCart") }}
              </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col
          cols="12"
          md="6"
          class="align-center d-flex d-sm-none"
          v-if="list"
        >
          <h3 class="list-name secondary--text">
            <strong>Lista: </strong><span>{{ list.name }}</span>
            <span class="ml-1">({{ products.length }} prodotti)</span>
          </h3>
        </v-col>
        <v-col cols="12" sm="4" lg="3" xl="2">
          <div
            v-if="$vuetify.breakpoint.xs"
            class="d-flex align-center space-between"
          >
            <ProductFacetFilterMobile
              :facets="facets"
              :facetsFilter="facetsFilter"
              :promoFilter="promoFilter"
              :newFilter="newFilter"
              :filteredProductsCount="filteredProductsCount"
              :hideFilterString="hideFilterString"
              @handlePromoFilter="handlePromoFilter"
              @handleNewFilter="handleNewFilter"
              @handleFacetFilterMobile="handleFacetFilterMobile"
              :hidePreferredFilter="true"
              :icon="'mdi-menu-down'"
            />

            <ProductSortFilter
              :sortFilter="sortFilter"
              @handleSortFilter="handleSortFilter"
              class="sort-filter-mobile"
            />
          </div>
          <div v-else>
            <CategoryMenu v-if="category" :category="category" />
            <h4 class="title-filter">FILTRA I PRODOTTI PER:</h4>
            <ProductPromoFilter
              v-if="!hidePromoFilter"
              :promoFilter="promoFilter"
              :newFilter="newFilter"
              @handlePromoFilter="handlePromoFilter"
              @handleNewFilter="handleNewFilter"
              :hidePreferredFilter="true"
            />
            <ProductFacetFilterDesktop
              v-if="$vuetify.breakpoint.smAndUp"
              :facets="facets"
              :facetsFilter="facetsFilter"
              :filteredProductsCount="filteredProductsCount"
              :hideFilterString="hideFilterString"
              :hidePreferredFilter="true"
              @handleFacetFilterMobile="handleFacetFilterMobile"
            />
          </div>
          <SupportCard class="mt-4" v-if="!$vuetify.breakpoint.xs" />
        </v-col>
        <v-col cols="12" sm="8" lg="9" xl="10" class="list-detail">
          <v-row class="list-detail-header align-center mb-6">
            <v-col
              cols="12"
              md="6"
              class="align-center d-none d-sm-flex"
              v-if="list"
            >
              <h3 class="list-name secondary--text">
                <strong>Lista: </strong><span>{{ list.name }}</span>
                <span class="ml-1">({{ products.length }} prodotti)</span>
              </h3>
            </v-col>

            <v-col cols="12" md="6" class="px-sm-0">
              <div
                class="d-flex justify-center justify-sm-start justify-md-end"
                :class="{ 'flex-column': $vuetify.breakpoint.xs }"
              >
                <v-tooltip
                  top
                  :open-on-hover="true"
                  :open-on-click="true"
                  max-width="500"
                  :disabled="editable"
                  class="mx-1"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-btn
                        outlined
                        color="primary"
                        class="bg-white"
                        depressed
                        :disabled="!editable"
                        @click="emptyList()"
                      >
                        <span>{{ $t("profile.lists.emptyList") }}</span>
                      </v-btn>
                    </div>
                  </template>
                  <span>{{ $t("profile.lists.notEditable") }}</span>
                </v-tooltip>

                <v-btn
                  outlined
                  color="primary"
                  class="bg-white mx-0 ml-sm-2 mr-sm-0"
                  depressed
                  :disabled="!products.length"
                  @click="goToListView"
                >
                  <span>
                    {{ $t("profile.lists.switchToList") }}
                  </span>
                </v-btn>
                <v-btn
                  color="primary"
                  class="mx-0 ml-sm-2 mr-sm-0"
                  depressed
                  @click="addAllToCart"
                  :disabled="!products.length"
                >
                  <span>
                    {{ $t("profile.lists.addAllToCart") }}
                  </span>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" class="pa-0">
              <ProductSortFilter
                v-if="!hideSort"
                :sortFilter="sortFilter"
                :wishlistItem="true"
                @handleSortFilter="handleSortFilter"
                class="sort-filter-desktop"
              />
            </v-col>
          </v-row>
          <div style="max-height:100%" v-if="totItems > 0">
            <v-row>
              <v-col
                cols="12"
                sm="6"
                lg="4"
                xl="3"
                v-for="product in products"
                :key="product.productId"
                class="product-col"
              >
                <ProductCard
                  v-bind:product="product"
                  :position="position"
                  :wishlistItem="true"
                  :deleteGranted="editable"
                  @removeItem="removeFromList"
                />
              </v-col>
            </v-row>
          </div>
          <div v-else-if="!loading" class="pa-12 text-center">
            <div class="no-products-title">
              {{ $t("profile.lists.noProduct") }}
            </div>
            <div class="pa-8">
              <img src="/no-products-cart.svg" alt="Nessun prodotto trovato" />
            </div>
          </div>

          <v-btn
            block
            v-intersect="onIntersect"
            :loading="loading"
            @click="loadMore"
            v-if="$vuetify.breakpoint.xs && hasMoreItems"
          >
            <template v-if="hasMoreItems">{{
              $t("products.showMore")
            }}</template>
            <template v-else>{{ $t("products.showAll") }}</template>
          </v-btn>

          <v-pagination
            v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
            :value="pageFilter"
            :page="pager.selPage"
            :length="pager.totPages ? pager.totPages : 0"
            :totalVisible="6"
            @next.stop.prevent="handlePageFilter"
            @prev="handlePageFilter"
            @input="handlePageFilter"
          ></v-pagination
        ></v-col>
      </v-row> -->
      <product-list-grid
        :parent-wishlist-id="list.listId"
        @productsCount="updateTotalItems"
        :hidePreferredFilter="true"
      />
      <SupportCard class="mt-4" v-if="$vuetify.breakpoint.xs" />
    </v-container>
  </div>
</template>
<style>
.list-detail-grid .product-grid {
  margin-top: 0;
}
</style>
<script>
// import ProductCard from "@/components/product/ProductCard.vue";
// import CategoryMenu from "@/components/navigation/CategoryMenu.vue";
// import ProductSortFilter from "@/components/product/filters/ProductSortFilter.vue";
// import ProductPromoFilter from "@/components/product/filters/ProductPromoFilter.vue";
// import ProductFacetFilterMobile from "@/components/product/filters/ProductFacetFilterMobile.vue";
// import ProductFacetFilterDesktop from "@/components/product/filters/ProductFacetFilterDesktop.vue";
import SupportCard from "@/components/cards/SupportCard.vue";
import ListService from "~/service/listService";

import ProductListGrid from "@/components/product/ProductListGrid.vue";
import { mapActions } from "vuex";
import toNumber from "lodash/toNumber";

export default {
  name: "ProductList",
  props: {
    mode: { type: String, required: false },
    category: { type: Object, required: false },
    position: { type: String, required: false }
  },
  components: {
    SupportCard,
    ProductListGrid
  },
  data() {
    return {
      loading: false,
      list: null,
      totalItems: 0,
      editable: true,
      key: 0
    };
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    updateTotalItems(count) {
      this.totalItems = count;
    },
    async emptyList() {
      const textConfirm = `Sei sicuro di voler svuotare la lista ${this.list.name}?`;

      let res = await this.$dialog.confirm({
        text: textConfirm
      });
      if (res) {
        const res = await ListService.emptyList(this.list.listId);

        this.list = res;
      }
    },
    async addAllToCart() {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await this.addProductsFromWishlist(this.list.listId);
      }
    },
    goToListView() {
      this.$router.replace({ path: `/profile/lists/${this.list.listId}` });
    },
    async removeFromList(product) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeItemFromList", {
          productName: product.name,
          productShortDescr: product.shortDescr
        })
      });
      if (res) {
        let item = this.list.wishlistItems.find(i => {
          return i.product.productId === product.productId;
        });
        if (item) {
          await ListService.removeItemFromList(
            this.list,
            // item.itemId,
            product.productId
          );
          await this.fetchProducts();
        }
      }
    },
    async reload(listId) {
      // this.list = this.$route.params.list;
      this.list = await ListService.getList(toNumber(listId));
      this.editable = this?.list?.sharingData?.grant != "READ";
      this.key++;
    }
  },
  async created() {
    this.reload(this.$route.params.listId);
  },
  watch: {
    async $route(to) {
      this.reload(to.params.listId);
    }
  }
};
</script>
